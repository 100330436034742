import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Breakpoint } from '../../types';
import { css } from '@emotion/core';

export const UnstyledLink = styled(Link)`
  color: inherit;
  display: inline-block;
  text-decoration: none;
  font-family: Barlow, sans-serif;
  font-size: 1.142rem;
  :hover {
    cursor: pointer;
  }
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      font-size: 2.8rem;
    `
  )};
  `;
