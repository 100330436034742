import { css, Global } from '@emotion/core';
import React, { useState } from 'react';

import { OverlayContext } from './OverlayProvider';

export type OverlayData = {
  open: boolean;
};

type Props = {
  children: any;
};

export function OverlayProvider({ children }: Props) {
  const [overlay, setOverlay] = useState<OverlayData>({
    open: false
  });

  function toggle() {
    const { open } = overlay;

    setOverlay({
      open: !open
    });
  }

  return (
    <OverlayContext.Provider value={{ toggle, overlay }}>
      <Global
        styles={css`
          html {
            overflow-y: ${overlay.open ? 'hidden' : 'auto'};
          }
        `}
      />
      {children}
    </OverlayContext.Provider>
  );
}
