import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { Fragment } from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Text } from '../UI/Text';

import ArnoLogo from './arnoLogo.svg';
import CwxCreditsLogo from './CwxCreditsLogo.svg';

export function SocialFooter() {
  return (
    <FooterContainer>
      <ArnoFootLogo src={ArnoLogo} />
      <MobileList>
        <StyledFind mode="gray" align="center">
          Find me on
        </StyledFind>
        <ListItem>
          <ListLink href="https://vimeo.com/arnopoppelaars">
            <Text mode="social">Vimeo</Text>
          </ListLink>
        </ListItem>
        <ListItem>
          <ListLink href="https://www.facebook.com/arno.poppelaars">
            <Text mode="social">Facebook</Text>
          </ListLink>
        </ListItem>
        <ListItem>
          <ListLink href="https://www.instagram.com/arno_poppelaars/">
            <Text mode="social">Instagram</Text>
          </ListLink>
        </ListItem>
      </MobileList>
      <CreditsContainer>
        <Text mode="gray">&copy; Arno Poppelaars</Text>
        <div>
          <CwxLink href="https://crossworx.be/">
            <MadeBy mode="gray">Website by </MadeBy>
            <CwxLogo src={CwxCreditsLogo} />
          </CwxLink>
        </div>
      </CreditsContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  position: relative;
  margin-left: 1rem;
  background: white;
  z-index: 0;
  margin-top: 3rem;

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 7rem;
    `
)}

${forBreakpoint(
  Breakpoint.Desktop,
  css`
      margin-top: -5rem;
    `
)}

  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-top: 14rem;
    `
)}
`;

const ArnoFootLogo = styled.img`
  position: absolute;
  bottom: -1.6rem;
  left: -2rem;
  opacity: 0.16;
  z-index: -1;
  pointer-events: none;
  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      opacity: 0.2;
      width: 50rem;
      left: -4rem;
    `
)}
`;

const MobileList = styled.ul`
  margin-top: 0.3rem;
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;

  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      margin-top: -1px;
      margin-bottom: 0px;
    `
)}
`;

const StyledFind = styled(Text)`
  margin-right: 0.8rem;
  margin-bottom: 0.3rem;

  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin-right: 2rem;
    `
)}
`;

const ListItem = styled.li`
  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
      display: inline;
      margin: 0 1rem;
    `
)}

  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
      margin: 0 3rem;
      opacity: 0.7;
    `
)}
`;

const ListLink = styled.a`
  text-decoration: none;
`;

const CreditsContainer = styled.div`
  ${forBreakpoint(
  Breakpoint.Phone,
  css`
        display: flex;
        justify-content: space-between;
        margin-right: 1.5rem;
        `
)};
`;


const CwxLink = styled.a`
  ${forBreakpoint(
  Breakpoint.TabletPortrait,
  css`
    margin-left: 1.5rem;
    `
)};
  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
    margin-left: 4rem;
    `
)};
`;

const MadeBy = styled(Text)`
  opacity: 0.7;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  margin-right: 0.5rem;
`;

const CwxLogo = styled.img`
  vertical-align: middle;
  margin-bottom: 0.2rem;
  ${forBreakpoint(
  Breakpoint.UltraWide,
  css`
    height: 35px;
    margin-bottom: 1rem;
    `
)};
`;