import React from 'react';
import Helmet from 'react-helmet';

import { OverlayProvider } from '../context/OverlayContext';

import { GlobalStyles } from './GlobalStyles';
import { Footer } from './Home/Footer';
import { Navbar } from './Navbar/Navbar';
import { Overlay } from './Navbar/Overlay';
import { SEO } from './SEO';

type Props = {
  children: any;
  includeFooter?: boolean;
  includeNavbar?: boolean;
};

export function Root({ includeNavbar = true, children, includeFooter = true }: Props) {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta content="IE=edge" http-equiv="X-UA-Compatible" />
        <meta content="True" name="HandheldFriendly" />
        <meta content="320" name="MobileOptimized" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <SEO />
      <OverlayProvider>
        <Overlay />
        {includeNavbar && <Navbar />}
        {children}
        {includeFooter && <Footer />}
      </OverlayProvider>
    </>
  );
}
