import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { useOverlay } from '../../context/useOverlay';
import { Breakpoint } from '../../types';
import { Urls } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { inBrowser } from '../../utils/inBrowser';
import { UnstyledLink } from '../UI/StyledLink';
import { Text } from '../UI/Text';

import logo from './logo.svg';
import phoneMenuClose from './mobileMenuClose.svg';
import phoneMenuOpen from './mobileMenuOpen.svg';

export function Navbar() {
  const { overlay, toggle } = useOverlay();

  function handleMenuClick() {
    toggle();
  }

  const open = overlay.open ? true : undefined;
  const url = inBrowser() ? window.location.pathname : '';

  return (
    <StyledNav>
      <StyledLink overlay={open} to="/">
        <StyledLogo src={logo} alt="Logo" />
        <StyledLogoText mode="logo">ARNO POPPELAARS</StyledLogoText>
      </StyledLink>
      <MobileMenuContainer overlay={open} onClick={handleMenuClick}>
        <MobileMenu
          src={open ? phoneMenuClose : phoneMenuOpen}
          overlay={open}
          alt="Menu"
        />
      </MobileMenuContainer>
      <StyledDesktopNavContainer>
        <StyledMenuItem
          active={url === Urls.Projects ? 1 : 0}
          to={Urls.Projects}
        >
          WORK
        </StyledMenuItem>
        <StyledMenuItem active={url === Urls.About ? 1 : 0} to={Urls.About}>
          ABOUT
        </StyledMenuItem>
      </StyledDesktopNavContainer>
    </StyledNav>
  );
}

export const MobileMenuContainer = styled.div<{ overlay?: boolean }>`
  margin-right: 1rem;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      display: none;
    `
  )};
  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      padding: 0.7rem;
      user-select: none;
    `
  )};
`;

const StyledNav = styled.nav`
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  display: flex;
  position: absolute;
  align-items: center;
  width: 90vw;
  z-index: 10;
  box-sizing: border-box;

  justify-content: space-between;

    ${forBreakpoint(
      Breakpoint.Phone,
      css`
        width: 100vw;
      `
    )}

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      width: 90vw;
      padding-right: 10vw;
    `
  )}

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      padding-right: 21.5vw;
    `
  )}

  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      padding-right: 30vw;
    `
  )}

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      padding-top: 3.5rem;
    `
  )}
`;

export const StyledLogo = styled.img`
  margin-right: 0.7rem;

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      width: 6.5rem;
      margin-right: 2rem;
    `
  )}
`;

const StyledLogoText = styled(Text)`
  flex-shrink: 0;
`;

const MobileMenu = styled.img<{ overlay?: boolean }>`
  cursor: pointer;
`;

const StyledLink = styled(Link)<{ overlay?: boolean }>`
  display: flex;
  text-decoration: none;
  align-items: center;
  opacity: ${props => (props.overlay ? 0.3 : 1)};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-left: 2rem;
    `
  )}
`;

const StyledDesktopNavContainer = styled.div`
  display: none;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      display: flex;
      justify-content: center;
      font-style: italic;
      margin-left: 25vw;
    `
  )};
`;

const StyledMenuItem = styled(UnstyledLink)<{ active: number }>`
  margin-right: 1.5rem;

  ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        margin-right: 4rem;
      `
    )}
    :hover {
    border-bottom: 1px solid black;
    margin-bottom: -1px;
  }

  ${props =>
    props.active &&
    css`
      border-bottom: 1px solid black;
      margin-bottom: -1px;
    `}
`;
