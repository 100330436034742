import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { Link } from 'gatsby';
import React from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Button } from '../UI/Button';
import { Text } from '../UI/Text';

import { SocialFooter } from './SocialFooter';

export function Footer() {
  return (
    <FooterContainer>
      <VideoContentCreator>
        <CenteredText mode="footer">
          <p>
            Arno Poppelaars is a video content creator based in Edegem, Belgium.
          </p>
        </CenteredText>
        <ReadMoreContactContainer>
          <ReadMore to="/about">
            <Text mode="underline">Read more</Text>
          </ReadMore>
          <Button mode="primary">
            <a href="tel:+32492633245">
              <Text mode="buttonSecondary">Contact</Text>
            </a>
          </Button>
        </ReadMoreContactContainer>
      </VideoContentCreator>
      <SocialFooterContainer>
        <SocialFooter />
      </SocialFooterContainer>
    </FooterContainer>
  );
}

const SocialFooterContainer = styled.div`
  margin-top: 12rem;
`;

const FooterContainer = styled.footer`
  background-color: white;
  position: relative;
  z-index: 100;
  padding-bottom: 1.5rem;
`;

const VideoContentCreator = styled.div`
  text-align: center;
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
  background-color: #eeeeee;
  padding: 7rem 3.5rem;
  z-index: 100;
  position: relative;

  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      margin-bottom: -9rem;
    `
  )};

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      clip-path: polygon(0 3%, 100% 0, 100% 97%, 0% 100%);
      padding: 12rem 3.5rem;
      margin-bottom: 0;
    `
  )};

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      clip-path: none;
    `
  )}

  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      padding: 13rem 18rem;
    `
  )}

  ${forBreakpoint(
    Breakpoint.Wide,
    css`
      padding: 16rem 18rem;
    `
  )}

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      padding: 30rem 18rem;
    `
  )}
`;

const ReadMoreContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenteredText = styled(Text)`
  max-width: 25rem;
  text-align: center;
  margin-bottom: 1.5rem;

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      max-width: 70rem;
      margin-bottom: 3rem;
    `
  )}
`;

const ReadMore = styled(Link)`
  text-decoration: none;
  margin-right: 2rem;

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-right: 5rem;
    `
  )}
`;
