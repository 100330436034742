import React from 'react';

import { OverlayData } from './OverlayContext';

type ContextValue = {
  toggle: () => void;
  overlay: OverlayData;
};

export const OverlayContext = React.createContext<ContextValue>({} as any);
