import { css, Global } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React from 'react';

import { useOverlay } from '../../context/useOverlay';
import { Urls } from '../../types';
import { inBrowser } from '../../utils/inBrowser';
import { Button } from '../UI/Button';
import { UnstyledLink } from '../UI/StyledLink';
import { Text } from '../UI/Text';

import arrowForward from './arrowForward.svg';

export function Overlay() {
  const { overlay, toggle } = useOverlay();

  const url = inBrowser() ? window.location.pathname : '';

  function handleClick(to: string) {
    if (inBrowser()) {
      if (window.location.pathname === to) {
        toggle();
      }
    }
  }

  return (
    <>
      {overlay.open && (
        <StyledOverlay>
          <Global
            styles={css`
              html {
                overflow: hidden;
              }
            `}
          />
          <BackgroundLine />
          <MenuItems>
            <StyledMenuText mode="italic">Menu</StyledMenuText>
            <UnstyledLink
              onClick={() => {
                handleClick(Urls.Projects);
              }}
              to={Urls.Projects}
            >
              <MenuItem active={url === Urls.Projects} mode="menuItem">
                Work
              </MenuItem>
            </UnstyledLink>
            <UnstyledLink
              onClick={() => {
                handleClick(Urls.About);
              }}
              to={Urls.About}
            >
              <MenuItem active={url === Urls.About} mode="menuItem">
                About
              </MenuItem>
            </UnstyledLink>
            <StyledOr mode="italic">Or</StyledOr>
            <UnstyledLink to="/showreel">
              <ShowReelButton mode="primary">
                Watch showreel{' '}
                <ArrowForward src={arrowForward} alt="Showreel" />
              </ShowReelButton>
            </UnstyledLink>
          </MenuItems>
        </StyledOverlay>
      )}
    </>
  );
}

const MenuItem = styled(Text) <{ active?: boolean }>`
  display: block;
`;

const StyledMenuText = styled(Text)`
  margin-bottom: 0.4rem;
`;
const StyledOr = styled(Text)`
  margin-top: 1.5rem;
  margin-bottom: 1.7rem;
`;

const ShowReelButton = styled(Button)`
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 15px;
`;

const BackgroundLine = styled.div`
  position: absolute;
  z-index: -1;
  display: block;
  top: 50%;
  left: 50%;
  height: 120%;
  color: #5d5d5d;
  opacity: 0.16;
  width: 1px;
  transform: translate(-50%, -50%) rotate(10deg);

  &:after {
    position: absolute;
    background-color: #000;
    content: '';
    height: 100%;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);

    animation-name: line-animation;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }

  @keyframes line-animation {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }
`;

const ArrowForward = styled.img`
  margin-left: 0.75rem;
`;

const MenuItems = styled.div`
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  overflow-y: hidden;
  background-color: #ffffff;
`;
