import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ComponentProps } from 'react';

import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';

type Props = ComponentProps<'div'> & {
  mode:
    | 'subtitle'
    | 'logo'
    | 'splashtitle'
    | 'italic'
    | 'underline'
    | 'menuItem'
    | 'footer'
    | 'buttonSecondary'
    | 'gray'
    | 'social'
    | 'about';
  align?: 'left' | 'center' | 'right';
  color?: string;
};

export function Text({ mode, align = 'left', ...props }: Props) {
  return <StyledText mode={mode} align={align} {...props} />;
}

const modes: Record<Props['mode'], SerializedStyles> = {
  subtitle: css`
    font-family: Barlow, sans-serif;
    color: #111111;
    font-size: 1.5rem;
    ${forBreakpoint(
      Breakpoint.TabletPortrait,
      css`
        font-size: 1.9rem;
      `
    )};
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 4.8rem;
      `
    )};
  `,
  splashtitle: css`
    font-family: Barlow, sans-serif;
    color: #111111;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.4rem;
  `,
  logo: css`
    font-family: Barlow, sans-serif;
    font-size: 1.142rem;
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 2.8rem;
      `
    )};
  `,
  italic: css`
    font-family: Barlow, sans-serif;
    text-transform: uppercase;
    font-style: italic;
    font-size: 1.142rem;
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 2.8rem;
      `
    )};
  `,
  underline: css`
    font-family: Work Sans, sans-serif;
    font-size: 0.875rem;
    border-bottom: 1px solid black;
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        margin-top: 1rem;
        font-size: 2.2rem;
        padding-bottom: 1rem;
        border-bottom: 1.5px solid black;
      `
    )};
  `,
  menuItem: css`
    font-family: Barlow, sans-serif;
    font-size: 2.5rem;
    line-height: 3.5rem;
  `,
  footer: css`
    font-family: Barlow, sans-serif;
    font-size: 1.5rem;
    line-height: 2rem;
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 3.9rem;
        line-height: 5.5rem;
      `
    )}
  `,
  gray: css`
    font-family: Barlow, sans-serif;
    color: #808080;
    font-size: 1rem;
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 2.5rem;
        line-height: 3rem;
      `
    )};
  `,
  social: css`
    font-size: 1rem;
    line-height: 1.6rem;
    ${forBreakpoint(
      Breakpoint.Desktop,
      css`
        font-size: 1rem;
        line-height: 1.5rem;
      `
    )};
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 2.4rem;
        line-height: 4rem;
      `
    )};
  `,
  buttonSecondary: css`
    font-family: Work-Sans, sans-serif;
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 2rem;
      `
    )}
  `,
  about: css`
    font-family: Barlow, sans-serif;
    color: #111111;
    font-size: 1.2rem;
    ${forBreakpoint(
      Breakpoint.TabletPortrait,
      css`
        font-size: 1.5rem;
        line-height: 2.3rem;
        letter-spacing: 0rem;
      `
    )};
    ${forBreakpoint(
      Breakpoint.Desktop,
      css`
        font-size: 2rem;
        line-height: 2.6rem;
      `
    )};
    ${forBreakpoint(
      Breakpoint.UltraWide,
      css`
        font-size: 5rem;
        line-height: 6.5rem;
      `
    )};
  `
};

const StyledText = styled.div<Props>`
  display: inline-block;
  font-family: 'Work Sans', Sans-Serif;
  color: ${(props: Props) => (props.color ? props.color : '#111111')};
  text-align: ${(props: Props) => props.align};
  line-height: 1.5rem;
  ${(props: Props) => modes[props.mode]};
`;
