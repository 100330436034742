import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

type siteData = {
  title: string;
  description: string;
  keywords: string[];
};

const settingsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings {
      title
      description
      keywords
    }
  }
`;

export function SEO() {
  return (
    <StaticQuery
      query={settingsQuery}
      render={data => {
        const site: siteData = data.site;

        return (
          <Helmet
            htmlAttributes={{ lang: 'nl' }}
            title={site.title}
            titleTemplate={site.title}
            meta={[
              {
                name: 'description',
                content: site.description
              },
              {
                property: 'og:title',
                content: site.title
              },
              {
                property: 'og:description',
                content: site.description
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: 'Arno Poppelaars'
              },
              {
                name: 'twitter:title',
                content: site.title
              },
              {
                name: 'twitter:description',
                content: site.description
              }
            ].concat(
              site.keywords && site.keywords.length > 0
                ? {
                    name: 'keywords',
                    content: site.keywords.join(', ')
                  }
                : []
            )}
          />
        );
      }}
    />
  );
}
