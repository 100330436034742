export type ProjectType = {
  Name: string;
  Year: string;
  Client: string;
  VideoUrl: string;
  Slug: string;
  Tags: string[];
  Thumb: any;
};

export type AboutInfo = {
  Intro: string;
  Services: string;
  Email: string;
  Phone: string;
  Street: string;
  Postal: string;
};

export enum Breakpoint {
  Phone,
  TabletPortrait,
  TabletLandscape,
  Desktop,
  Wide,
  UltraWide
}

export type Breakpoints<T> = Partial<Record<Breakpoint, T>>;

export enum Urls {
  Home = '/home',
  Projects = '/projects',
  About = '/about'
}

export type VimeoProps = {
  width: number;
  height: number;
};
