import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { ComponentProps, forwardRef } from 'react';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Breakpoint } from '../../types';

type Props = ComponentProps<'button'> & {
  mode: 'primary';
};

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ type = 'button', ...props }, ref) => (
    <StyledButton ref={ref} type={type} {...props} />
  )
);

const modes: Record<Props['mode'], SerializedStyles> = {
  primary: css`
    font-family: Barlow, sans-serif;
  `
};

const StyledButton = styled.button<Props>`
  position: relative;
  display: flex;
  align-items: center;
  padding:  0 0.6rem;
  border: 1px solid #707070;
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: nowrap;
  transition: opacity 0.25s ease, transform 0.1s ease;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
  :active {
    transform: scale(0.95);
  }
  :focus {
    outline: 0;
  }
  ${props => modes[props.mode]};

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
     border-radius: 12px;
     padding: 1rem 1.8rem;
    `
  )}
`;
