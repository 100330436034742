import { Breakpoint } from './types';

export const BREAKPOINTS: Record<Breakpoint, number> = {
  [Breakpoint.Phone]: 0,
  [Breakpoint.TabletPortrait]: 37.5,
  [Breakpoint.TabletLandscape]: 56.25,
  [Breakpoint.Desktop]: 75,
  [Breakpoint.Wide]: 125,
  [Breakpoint.UltraWide]: 180
};

export const DiagonalAngleWidth: number = 28.67454;
export const DesktopWidth: number = 900;

export const PolygonLeft: string = 'polygon(0 0, 100% 0%, 85% 100%, 0% 100%);';
export const PolygonRight: string = 'polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);';
