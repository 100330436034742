import { css, SerializedStyles } from '@emotion/core';

import { BREAKPOINTS } from '../constants';
import { Breakpoint } from '../types';

export function forBreakpoint(breakpoint: Breakpoint, style: SerializedStyles) {
  if (breakpoint === Breakpoint.Phone) {
    return style;
  }

  return css`
    @media (min-width: ${BREAKPOINTS[breakpoint]}rem) {
      ${style};
    }
  `;
}
